import React, {useEffect, useState} from "react"
import LogoKh from "../logo/logo-kh";
import {Link} from "gatsby";
import {useLocation} from "@reach/router";
import LocomotiveScroll from "locomotive-scroll";
import LogoText from "../logo/logo-text";
import {X} from "@phosphor-icons/react";

const NavigationHeader = () => {

    const location = useLocation();
    const [scrollPast, setScrollPast] = useState(false);
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        // @ts-ignore
        let locomotive_scroll: LocomotiveScroll = window['scroll'];
        locomotive_scroll.on('scroll', (instance) => {
            if (instance.scroll.y > 700) {
                setScrollPast(true)
            }
            else {
                setScrollPast(false)
            }
        })
    }, [location.pathname]);

    const transparent = !scrollPast && location.pathname === '/'

    const LINKS = [
        {
            title: 'About',
            to: '/about'
        },
        {
            title: 'Projects',
            to: '/projects'
        },
        {
            title: 'Contact',
            to: '/contact'
        }
    ]


    return (
        <>
            <header className={"fixed top-0 h-20 w-full z-50"}>
                <div className={`absolute h-32 pointer-events-none bg-gradient-to-b from-zinc-900/50 w-full ${transparent ? 'opacity-100' : 'opacity-0'} transition duration-300`} style={{ zIndex: "-1" }}/>
                <div className={`absolute h-20 pointer-events-none bg-white border-b w-full ${transparent ? 'opacity-0' : 'opacity-100'} transition duration-300`} style={{ zIndex: "-1" }}/>
                <div className={"z-50 custom-container flex items-center h-full"}>
                    <Link to={'/'} className={`flex items-center`}>
                        <LogoKh className={"h-8"}/>
                        <LogoText className={`h-4 ml-4 transition duration-300 ${transparent ? 'opacity-0' : 'opacity-100'}`}/>
                    </Link>
                    <div className={"ml-auto"}/>
                    {
                        LINKS.map(link => {
                            return (
                                <Link key={link.to} to={link.to} className={`${transparent ? 'text-white hover:bg-white/10 focus-visible:bg-white/10' : 'text-black hover:bg-black/10 focus-visible:bg-black/10'} subtitle-1 ml-12 hidden sm:block px-3.5 py-1 rounded-full transition duration-300`}>
                                    {link.title}
                                </Link>
                            )
                        })
                    }
                    <button onClick={() => { setMenu(true) }} className={`sm:hidden flex items-center justify-center space-x-3 rounded-full border py-2.5 px-3 ${transparent ? 'border-white/30 hover:bg-white/10' : 'border-zinc-600/30 hover:bg-zinc-600/10'}`}>
                        <div className={`rounded-full w-2.5 h-2.5 ${transparent ? 'bg-white ' : 'bg-zinc-700'}`}/>
                        <div className={`rounded-full w-2.5 h-2.5 ${transparent ? 'bg-white ' : 'bg-zinc-700'}`}/>
                    </button>
                </div>
                <div className={`fixed w-full h-full top-0 left-0 p-8 transform ${menu ? 'translate-x-0' : 'translate-x-full'} transition duration-300 ease-linear`} style={{ background: 'rgb(210, 215, 185)', transition: '.3s cubic-bezier(0.22, 0.61, 0.36, 1)' }}>
                    <button onClick={() => { setMenu(false) }} className={'bg-white/70 rounded-full p-2 absolute right-5 top-5'}>
                        <X size={26}/>
                    </button>
                    <div className={'flex flex-col mt-8'}>
                        {
                            LINKS.map(link => {
                                return (
                                    <Link key={link.to} to={link.to} className={`heading-2 font-medium py-3 w-full transition duration-300 tracking-wide`} onClick={() => { setMenu(false) }}>
                                        {link.title}
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </header>
        </>
    )

}

export default NavigationHeader;